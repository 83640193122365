import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useRouter } from 'next/router';

import { FiftyFiftyComponent } from '@/types';

import { convertMarkdown, useIsMobile } from '@/ui/utils';
import { colorPickerHexToClassName } from '@/ui/constants';
import {
  ComponentCtaButton,
  Icon,
  ResponsiveImage,
  MarketoForm,
  Modal,
} from '@/ui';

import styles from './ComponentFiftyFiftyCallout.module.scss';
import mediaCard from '../../base/MediaCard/MediaCard.module.scss';

const ComponentFiftyFiftyCallout = ({
  sys,
  calloutHeading,
  calloutBody,
  calloutImage,
  calloutStyle,
  calloutCaption,
  pillarColour,
  calloutCta,
  textAlignment,
  previewVideo,
  enableLightbox,
  playWithoutSound,
  gatedMediaCheckbox,
  gatedMediaMarketoFormId,
}: FiftyFiftyComponent) => {
  const isMuted = playWithoutSound;

  const router = useRouter();
  const { locale = 'en' } = router;

  const [isVideo, setIsVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [imageColumnHeight, setImageColumnHeight] = useState(0);
  const [contentInViewport, setContentInViewport] = useState(false);

  const [isImageOpen, setIsImageOpen] = useState(false);
  const [gatedAccepted, setGatedAccepted] = useState(false);
  const [gatedModelOpen, setGatedModelOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const allowContent = !gatedMediaCheckbox || gatedAccepted;

  const contentRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const imageColumnRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLImageElement>(null);

  const isMobile = useIsMobile();
  const hasMedia = calloutImage?.url;
  const isImage = calloutImage?.contentType.startsWith('image');
  const imageId = `calloutImage-${sys?.id}`;
  const lightBox = enableLightbox && isImage;

  const styleBranded = calloutStyle.toLowerCase().includes('branded');
  const imageRight = calloutStyle.toLowerCase().includes('right');

  const imageSide = imageRight ? 'right' : 'left';
  const styleType = styleBranded ? 'branded' : 'standard';

  const styleColour = pillarColour
    ? colorPickerHexToClassName[pillarColour]
    : '';

  const setAlignment = 'align_' + textAlignment?.toLowerCase() || '';

  const contentLineHeight = 42;
  let maxHeight = isExpanded
    ? 'none'
    : `${
        Math.floor(imageColumnHeight / contentLineHeight) * contentLineHeight
      }px`;
  if (isMobile) {
    maxHeight = isExpanded ? 'none' : `350px`;
  }

  const adjustedHeight = (styleBranded ? 128 : 0) + 40;

  let showMoreBtnText = isExpanded ? '- Hide' : '+ Read More';
  if (locale === 'fr') {
    showMoreBtnText = isExpanded ? '- Masquer' : '+ Lire plus';
  }

  const togglePlayPause = () => setIsPlaying(!isPlaying);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
    if (isMobile && isExpanded && containerRef.current && !contentInViewport) {
      window.scrollTo({
        top: containerRef.current.offsetTop - 100,
        behavior: 'smooth',
      });
    }
  };

  const updateImageColumnHeight = () => {
    if (imageColumnRef.current) {
      setImageColumnHeight(
        imageColumnRef.current.clientHeight - adjustedHeight
      );
    }
  };

  const checkTextOverflow = () => {
    if (textRef.current && containerRef.current) {
      const isOverflowing =
        textRef.current.scrollHeight >
        (textRef.current.clientHeight ||
          containerRef.current.clientHeight - adjustedHeight);
      setShowReadMore(isOverflowing);
    }
  };

  useEffect(() => {
    setIsVideo(!isImage);
  }, [calloutImage, isImage]);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        updateImageColumnHeight();
        checkTextOverflow();
      }, 10);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [calloutBody, imageColumnHeight]);

  useEffect(() => {
    if (imageRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => setContentInViewport(entry.isIntersecting),
        { threshold: 0.5 }
      );
      observer.observe(imageRef.current);
      return () => observer.disconnect();
    }
  }, [contentRef]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleFormClose = () => {
    setGatedModelOpen(false);
  };

  const handleFormSuccess = () => {
    setGatedModelOpen(false);
    setGatedAccepted(true);
    setIsPlaying(true);
  };

  const handleImageClick = () => {
    if (lightBox) {
      setIsImageOpen(true);
    }
  };

  const renderVideoContent = () => {
    if (!isMounted) {
      return null;
    }

    if (gatedMediaCheckbox && !gatedAccepted) {
      if (!previewVideo) {
        return (
          <div
            className={`${styles.fiftyFiftyCallout__video} ${styles.gatedNoVideo}`}
          >
            Gated content has been set up incorrectly. <br />
            Please check that a Preview video has been uploaded.
          </div>
        );
      }
      return (
        <ReactPlayer
          className={styles.fiftyFiftyCallout__video}
          url={previewVideo.url}
          width='100%'
          height='100%'
          playing={isPlaying}
          playsinline
          controls={!isMuted && isPlaying}
          muted={isMuted}
          onEnded={() => {
            setIsPlaying(false);
            gatedMediaMarketoFormId
              ? setGatedModelOpen(true)
              : alert(
                  'Gated content has been set up incorrectly.\nPlease check that a Marketo Form ID has been added.'
                );
          }}
        />
      );
    }

    if (isVideo && hasMedia && allowContent) {
      return (
        <ReactPlayer
          className={styles.fiftyFiftyCallout__video}
          url={calloutImage.url}
          width='100%'
          height='100%'
          playing={isPlaying}
          playsinline
          controls={!isMuted && isPlaying}
          muted={isMuted}
          onEnded={() => {
            setIsPlaying(false);
          }}
        />
      );
    }

    return null;
  };

  return (
    <div className={`${styles.fiftyFiftyCallout} ${styles[styleType]}`}>
      <div
        className={`${styles.fiftyFiftyCallout__inner} ${styles[imageSide]}`}
      >
        <div ref={containerRef} className={styles.fiftyFiftyCallout__imageColumn}>
          <div
            ref={imageColumnRef}
            className={`${styles.fiftyFiftyCallout__imageWrapper} ${
              isPlaying ? styles.playing : ''
            } ${isVideo ? styles.video : styles.image} ${
              !isMuted ? styles.sound : ''
            } ${
              lightBox ? mediaCard.mediaCard__lightbox : ''
            }`}
          >
            {isVideo && (
              <button
                type='button'
                className={`${styles.fiftyFiftyCallout__playButton} ${
                  isPlaying ? styles.pause : styles.play
                }`}
                onClick={togglePlayPause}
              >
                <Icon
                  className={styles.fiftyFiftyCallout__playIcon}
                  id={isPlaying ? 'pause' : 'play'}
                  width={isPlaying ? 30 : 40}
                  height={isPlaying ? 30 : 40}
                />
              </button>
            )}
            {isImage && hasMedia && (
              <ResponsiveImage
                media={calloutImage}
                sizes={{ sm: 400, md: 700 }}
                className={styles.fiftyFiftyCallout__image}
                onClick={lightBox ? handleImageClick : undefined}
              />
            )}
            {renderVideoContent()}
          </div>
          {calloutCaption && (
            <div
              className={`${styles.fiftyFiftyCallout__caption} ${styles[styleColour]}`}
            >
              <span>{calloutCaption}</span>
            </div>
          )}
        </div>
        <div
          ref={contentRef}
          className={`${styles.fiftyFiftyCallout__content} ${
            calloutCaption ? styles.fiftyFiftyCallout__spacing : ''
          } ${textAlignment ? styles[setAlignment] : styles['align_top']}
          `}
        >
          {isMobile && styleBranded && calloutCaption && (
            <div
              className={`${styles.fiftyFiftyCallout__caption} ${
                styles[styleColour]
              } ${styleBranded ? styles.branded : ''}`}
            >
              <span>{calloutCaption}</span>
            </div>
          )}
          <div
            ref={textRef}
            className={`${styles.fiftyFiftyCallout__contentInner} ${
              showReadMore
                ? isExpanded
                  ? styles.expanded
                  : styles.collapsed
                : ''
            } ${styles[styleColour]}`}
            style={{ maxHeight }}
          >
            <h2 className={styles.fiftyFiftyCallout__heading}>
              {calloutHeading}
            </h2>
            {calloutBody && (
              <div className={`${styles.fiftyFiftyCallout__text}`}>
                {convertMarkdown(calloutBody)}
              </div>
            )}

            {calloutCta && (
              <div className={styles.fiftyFiftyCallout__cta}>
                <ComponentCtaButton
                  {...calloutCta}
                  ctaType='link'
                  tabIndex={!isExpanded ? -1 : 0}
                />
              </div>
            )}
          </div>
          {showReadMore && (
            <button
              onClick={toggleReadMore}
              className={styles.fiftyFiftyCallout__readMore}
            >
              {showMoreBtnText}
            </button>
          )}
        </div>
        {gatedMediaMarketoFormId && (
          <Modal
            handleClose={() => handleFormClose()}
            isOpen={gatedModelOpen}
            triggerId={imageColumnRef?.current?.id ?? ''}
          >
            <MarketoForm
              key='marketoEnquiry'
              formId={gatedMediaMarketoFormId}
              successMessage='Form submitted'
              onSuccess={handleFormSuccess}
            />
          </Modal>
        )}
        {lightBox && (
          <Modal
            handleClose={() => setIsImageOpen(false)}
            isOpen={isImageOpen}
            triggerId={imageId}
            fullContent
            isLightBox
          >
            <div className={mediaCard.mediaCard__lightboxContainer}>
              <div className={'loading'}>
                <Icon
                  id='loading'
                  className={`${styles.loadingIcon}`}
                  width={44}
                  height={44}
                />
              </div>
              <ResponsiveImage
                sizes={{ sm: 1600, md: 1600, lg: 1600 }}
                className={mediaCard.mediaCard__lightboxModalImage}
                media={calloutImage}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ComponentFiftyFiftyCallout;
