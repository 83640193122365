import styles from './ComponentIntroduction.module.scss';

import {
  Pillars,
  ComponentCtaButton,
  RelatedInsightWrapper,
  convertMarkdown,
  AuthorCardSmall,
  MediaCard,
} from '@/ui';

import type {
  IntroductionComponent,
  PagePreview,
  PillarItem,
  AuthorContent,
} from '@/types';

type PillarContent = PillarItem | PagePreview | AuthorContent;

const ComponentIntroduction = ({
  textBoxBody,
  pillarContent,
  cta,
  introductionMedia, 
  bgColour = 'white',
  removeAspectRatio = false,
}: IntroductionComponent) => {
  const bgColourClass = bgColour?.toLowerCase().replace(' ', '-');

  function isPillar(pillarContent: PillarContent): pillarContent is PillarItem {
    return (pillarContent as PillarItem).pillarText !== undefined;
  }

  function isRelatedInsight(
    pillarContent: PillarContent
  ): pillarContent is PagePreview {
    return (pillarContent as PagePreview).pageHeading !== undefined;
  }

  function isAuthorContent(
    pillarContent: PillarContent
  ): pillarContent is AuthorContent {
    return (pillarContent as AuthorContent).employeeTitle !== undefined;
  }

  const pillars = pillarContent.filter((pillarContentItem) =>
    isPillar(pillarContentItem)
  ) as Array<PillarItem>;

  const relatedInsights = pillarContent.filter((pillarContentItem) =>
    isRelatedInsight(pillarContentItem)
  ) as Array<PagePreview>;

  const AuthorContent = pillarContent.filter((pillarContentItem) =>
    isAuthorContent(pillarContentItem)
  ) as Array<AuthorContent>;

  return (
    <div className={`${styles.introduction} bg__${bgColourClass}`}>
      <div className={styles.introduction__container}>
        <div className={styles.introduction__grid}>
          <div
            className={`${styles.introduction__main}  ${
              !pillarContent && styles.full
            }`}>

            {introductionMedia && (
                <div className={styles.introduction__image}>
                  <div className={styles.richTextBody__mediaCard}>
                    <MediaCard removeAspectRatio={removeAspectRatio} {...introductionMedia}/>
                  </div>
                </div>
              )
            }

            {textBoxBody && (
              <div className={styles.introduction__text}>
                {convertMarkdown(textBoxBody)}
              </div>
            )}

            {cta && (
              <div className={styles.introduction__ctaContainer}>
                <ComponentCtaButton {...cta} ctaType='link' />
              </div>
            )}
          </div>
          {(!!pillars.length ||
            !!relatedInsights.length ||
            !!AuthorContent.length) && (
            <div className={styles.introduction__aside}>
              {!!pillars.length && <Pillars pillars={pillars} />}
              {!!relatedInsights.length && (
                <RelatedInsightWrapper
                  pagePreviews={relatedInsights}
                  headingVariant='h2'
                />
              )}
              {!!AuthorContent.length && (
                <AuthorCardSmall {...AuthorContent[0]} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComponentIntroduction;
