import styles from './MediaCard.module.scss';

import ReactPlayer from 'react-player/lazy';
import { useState, useEffect } from 'react';

import { MediaCardProps } from '@/types';

import { ResponsiveImage, Modal, MarketoForm, Icon } from '@/ui';
import { colorPickerHexToClassName } from '@/ui/constants';

const returnMediaType = (path = '') => {
  if (!path) return '';

  return path.includes('https://videos.ctfassets.net') ||
    path.includes('youtube.com/watch?v=') ||
    path.includes('player.vimeo')
    ? 'video'
    : 'image';
};

export const MediaCard = ({
  sys,
  assetSourceLocal,
  assetSourceRemote,
  videoThumbnail,
  videoLength,
  assetDescription,
  pillarColour,
  enableLightbox = false,
  previewVideo,
  playWithoutSound,
  gatedMediaCheckbox,
  gatedMediaMarketoFormId,
  removeAspectRatio
}: MediaCardProps) => {
  const isMuted = playWithoutSound;

  const [isPlaying, setIsPlaying] = useState(false);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [videoIsLoading, setVideoIsLoading] = useState(true);

  const [modelOpen, setModelOpen] = useState(false);

  const [gatedAccepted, setGatedAccepted] = useState(false);
  const [gatedModelOpen, setGatedModelOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const allowContent = !gatedMediaCheckbox || gatedAccepted;

  const lightBox =
    enableLightbox && returnMediaType(assetSourceLocal?.url) === 'image';
  const isRemoteSource = !!assetSourceRemote;
  const mediaType = returnMediaType(
    isRemoteSource ? assetSourceRemote : assetSourceLocal?.url
  );
  const id = `media-${sys.id}`;

  const handleVideoPlay = () => {
    setModelOpen(true);
    setIsPlaying(true);
  };

  const handleImageClick = () => {
    if (lightBox) {
      setIsImageOpen(true);
    }
  };

  const handleOnReady = () => {
    setVideoIsLoading(false);
  };

  const styleColour = pillarColour
    ? colorPickerHexToClassName[pillarColour]
    : '';

  let media;

  if (isRemoteSource) {
    media = mediaType === 'video' ? videoThumbnail : { url: assetSourceRemote };
  } else {
    media = mediaType === 'video' ? videoThumbnail : assetSourceLocal;
  }

  const handleFormClose = () => {
    setGatedModelOpen(false);
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleFormSuccess = () => {
    setGatedModelOpen(false);
    setGatedAccepted(true);
    setIsPlaying(true);
  };

  const renderVideoContent = () => {
    if (!isMounted) {
      return null;
    }

    if (gatedMediaCheckbox && !gatedAccepted) {
      if (!previewVideo) {
        return (
          <div
            className={`${styles.mediaCard__videoEl} ${styles.gatedNoVideo}`}
          >
            Gated content has been set up incorrectly. <br />
            Please check that a Preview video has been uploaded.
          </div>
        );
      }
      return (
        <ReactPlayer
          className={styles.mediaCard__videoEl}
          url={previewVideo.url}
          width='100%'
          height='100%'
          playing={isPlaying}
          controls={!isMuted && isPlaying}
          muted={isMuted}
          onReady={() => handleOnReady}
          onEnded={() => {
            setIsPlaying(false);
            gatedMediaMarketoFormId
              ? setGatedModelOpen(true)
              : alert(
                  'Gated content has been set up incorrectly.\nPlease check that a Marketo Form ID has been added.'
                );
          }}
        />
      );
    }

    if (allowContent) {
      return (
        <ReactPlayer
          className={styles.mediaCard__videoEl}
          url={isRemoteSource ? assetSourceRemote : assetSourceLocal?.url}
          width='100%'
          height='100%'
          playing={isPlaying}
          controls={!isMuted && isPlaying}
          muted={isMuted}
          onReady={() => handleOnReady}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if ((mediaType === 'video' && !videoThumbnail) || mediaType === '') {
    return null;
  }

  return (
    <div className={styles.mediaCard}>
      <div
        className={`${styles.mediaCard__media} ${
          lightBox ? styles.mediaCard__lightbox : ''
        } ${removeAspectRatio ? 'no-aspect-ratio' : ''}`}
      >
        <ResponsiveImage
          sizes={{ sm: 400, md: 845, lg: 1024 }}
          className={styles.mediaCard__image}
          media={media}
          onClick={lightBox ? handleImageClick : undefined}
        />
        {mediaType === 'video' && (
          <>
            <button
              type='button'
              onClick={() => {
                handleVideoPlay();
              }}
              className={styles.mediaCard__playButton}
              id={id}
            >
              <Icon
                className={styles.mediaCard__playIcon}
                id='play'
                width={40}
                height={40}
              />
            </button>
            <Modal
              handleClose={() => {
                setModelOpen(false);
                setIsPlaying(false);
              }}
              isOpen={modelOpen}
              triggerId={id}
              modalClassname={styles.modal__videoContainer}
            >
              <div className={`${styles.mediaCard__videoContainer} ${isPlaying ? styles.playing : styles.stopped}`}>
                <button
                  type='button'
                  className={`${styles.mediaCard__playButton} ${
                    isPlaying ? styles.pause : styles.play
                  }`}
                  onClick={() => {
                    togglePlayPause();
                  }}
                >
                  <Icon
                    className={styles.mediaCard__playIcon}
                    id={isPlaying ? 'pause' : 'play'}
                    width={isPlaying ? 30 : 40}
                    height={isPlaying ? 30 : 40}
                  />
                </button>

                {videoIsLoading && (
                  <Icon
                    className={styles.mediaCard__loading}
                    id='loading'
                    width={44}
                    height={44}
                  />
                )}
                {renderVideoContent()}
              </div>
            </Modal>
          </>
        )}
      </div>
      {assetDescription && (
        <div className={`${styles.mediaCard__caption} ${styles[styleColour]}`}>
          {videoLength && (
            <span className={styles.mediaCard__videoLength}>{videoLength}</span>
          )}
          {assetDescription}
        </div>
      )}
      {lightBox && (
        <Modal
          handleClose={() => setIsImageOpen(false)}
          isOpen={isImageOpen}
          triggerId={id}
          fullContent
          isLightBox
        >
          <div className={styles.mediaCard__lightboxContainer}>
            <div className={'loading'}>
              <Icon
                id='loading'
                className={`${styles.loadingIcon}`}
                width={44}
                height={44}
              />
            </div>
            <ResponsiveImage
              sizes={{ sm: 1600, md: 1600, lg: 1600 }}
              className={styles.mediaCard__lightboxModalImage}
              media={media}
            />
          </div>
        </Modal>
      )}
      {gatedMediaMarketoFormId && (
        <Modal
          handleClose={() => handleFormClose()}
          isOpen={gatedModelOpen}
          triggerId={id}
        >
          <MarketoForm
            key='marketoEnquiry'
            formId={gatedMediaMarketoFormId}
            successMessage='Form submitted'
            onSuccess={handleFormSuccess}
          />
        </Modal>
      )}
    </div>
  );
};

export default MediaCard;
