import 'keen-slider/keen-slider.min.css';
import styles from './Carousel.module.scss';

import { ReactNode, useState } from 'react';
import { useKeenSlider, KeenSliderPlugin } from 'keen-slider/react';

import { Icon } from '@/ui/base';

interface CarouselProps {
  id: string;
  className?: string;
  loadedClass?: string;
  accessibleTitle: string;
  slideCountLabel?: string;
  slideCurrentToken?: string;
  slideTotalToken?: string;
  slideLabel?: string;
  previousLabel?: string;
  nextLabel?: string;
  autoplay?: boolean;
  children: ReactNode;
  slideDelay?: number;
  controlsOverlay?: boolean;
  slidesPerView?: number;
  spacing?: number;
  loop?: boolean;
  overflow?: string;
  showDots?: boolean;
}

const adaptiveHeight: KeenSliderPlugin = (slider) => {
  function updateHeight() {

    const slidesPerView = typeof slider.options.slides === 'object'
        ? slider.options.slides?.perView || 1
        : 1;
    const currentIndex = slider.track.details.rel;
    const totalSlides = slider.slides.length;

    const visibleSlides = [];
    const perView = typeof slidesPerView === 'number' ? slidesPerView : 1;
    for (let i = 0; i < perView; i++) {
      const slideIndex = (currentIndex + i) % totalSlides;
      visibleSlides.push(slider.slides[slideIndex]);
    }

    let maxHeight = 0;
    visibleSlides.forEach((slide) => {
      if (!slide || !slide.firstElementChild) return;
      const slideHeight = slide.firstElementChild.clientHeight;
      maxHeight = Math.max(maxHeight, slideHeight);
    });

    slider.container.style.height = `${maxHeight}px`;
  }

  const resizeObserver = new ResizeObserver(() => {
    updateHeight();
  });

  slider.on('created', () => {
    updateHeight();
    resizeObserver.observe(slider.container);
    slider.container.classList.add(styles.init);
  });

  slider.on('destroyed', () => {
    resizeObserver.unobserve(slider.container);
  });

  slider.on('slideChanged', updateHeight);
  slider.on('updated', updateHeight);
};

const autoplayCarousel = (delay: number): KeenSliderPlugin => (slider) => {
  let timeout: ReturnType<typeof setTimeout>;
  let mouseOver = false;

  function clearNextTimeout() {
    clearTimeout(timeout);
  }

  function nextTimeout() {
    clearTimeout(timeout);

    if (mouseOver) {
      return;
    }

    timeout = setTimeout(() => {
      slider.next();
    }, delay);
  }

  slider.container.addEventListener('mouseover', () => {
    mouseOver = true;
    clearNextTimeout();
  });

  slider.container.addEventListener('mouseout', () => {
    mouseOver = false;
    nextTimeout();
  });

  nextTimeout();

  slider.on('dragStarted', clearNextTimeout);
  slider.on('animationEnded', nextTimeout);
  slider.on('updated', nextTimeout);
};

const keyboardEvents: KeenSliderPlugin = (slider) => {
  slider.container.addEventListener('keydown', (event) => {
    const key = event.key;

    switch (key) {
      case 'ArrowLeft':
        slider.prev();
        break;
      case 'ArrowRight':
        slider.next();
        break;
    }
  });
};

const Carousel = ({
  id,
  className = '',
  loadedClass = '',
  accessibleTitle,
  slideCountLabel = 'item {CURRENT} of {TOTAL}',
  slideCurrentToken = '{CURRENT}',
  slideTotalToken = '{TOTAL}',
  slideLabel = 'slide',
  previousLabel = 'previous',
  nextLabel = 'next',
  autoplay = false,
  children,
  slideDelay = 5000,
  controlsOverlay = false,
  slidesPerView = 1,
  spacing = 0,
  loop = true,
  showDots = true,
}: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [totalSlides, setTotalSlides] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: loop,
      slides: {
        perView: slidesPerView,
        spacing: spacing,
      },
      slideChanged(slider) {
        const current = slider.track.details.rel;
        setCurrentSlide(current);

        slider.slides.forEach((slide, index) => {
          const slideInView =
            (index >= current && index < current + slidesPerView) ||
            (index < current &&
              index + slider.slides.length < current + slidesPerView);
          if (slideInView) {
            slide.classList.add('in-view');
            slide.removeAttribute('aria-hidden');
            slide.removeAttribute('tabindex');
          } else {
            slide.classList.remove('in-view');
            slide.setAttribute('aria-hidden', 'true');
            slide.setAttribute('tabindex', '-1');
          }
        });
      },
      created(slider) {
        setTotalSlides(slider.track.details.slides.length);

        slider.slides.forEach((slide, index) => {
          const slideInView =
            index < slidesPerView ||
            index + slider.slides.length < slidesPerView;
          if (slideInView) {
            slide.classList.add('in-view');
          } else {
            slide.setAttribute('aria-hidden', 'true');
            slide.setAttribute('tabindex', '-1');
          }
        });

        if (autoplay && slider.slides.length > 1) {
          autoplayCarousel(slideDelay)(slider);
        }

        setLoaded(true);
      },
    },
    [adaptiveHeight, keyboardEvents]
  );

  const prevDisabled = !loop && currentSlide === 0;
  const nextDisabled = !loop && (currentSlide >= totalSlides - slidesPerView);

  const slideCount = () =>
    slideCountLabel
      .replace(slideCurrentToken, Number(currentSlide + 1).toString())
      .replace(slideTotalToken, totalSlides.toString());

  const isCarousel = totalSlides > 1 && loaded && instanceRef.current;

  const isOverlay = controlsOverlay && isCarousel;

  const controlsBelow = isCarousel && !isOverlay;

  return (
    <section
      className={`${className} ${loaded ? loadedClass : ''} ${
        styles.carousel__overlay
      }`}
      aria-label={accessibleTitle}
      aria-roledescription='carousel'
      id={id}
    >
      {isCarousel && (
        <div className={styles.carousel__liveregion} aria-live='polite'>
          {slideCount()}
        </div>
      )}
      <div className={`keen-slider ${styles.carousel__slider}`} ref={sliderRef}>
        {children}
      </div>
      {controlsBelow && (
        <>
          <div className={`${styles.carousel__controls} carousel__controls`}>
            {showDots && (
              <ul className={`${styles.carousel__controlList} carousel__dots`}>
                {[
                  ...Array(
                    instanceRef.current?.track.details.slides.length
                  ).keys(),
                ].map((idx) => {
                  const isCurrent = currentSlide === idx;
                  return (
                    <li key={`${id}-dot-${idx}`} aria-current={isCurrent}>
                      <button
                        type='button'
                        className={`${styles.carousel__dot} ${
                          isCurrent ? styles.active : ''
                        }`}
                        onClick={() => instanceRef.current?.moveToIdx(idx)}
                        aria-label={slideLabel.replace(
                          slideCurrentToken,
                          `${idx + 1}`
                        )}
                        aria-controls={id}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
            <ul className={`${styles.carousel__controlList} carousel__arrows`}>
              <li>
                <button
                  type='button'
                  disabled={prevDisabled}
                  onClick={() => !prevDisabled && instanceRef?.current?.prev()}
                  className={`${styles.carousel__button} ${styles.prev} ${prevDisabled ? styles.disabled : ''}`}
                  aria-controls={id}
                >
                  <Icon id='chevron-arrow' width={9} height={16} />
                  <span className={styles.carousel__buttonText}>
                    {previousLabel}
                  </span>
                </button>
              </li>
              <li>
                <button
                  type='button'
                  disabled={nextDisabled}
                  onClick={() => !nextDisabled && instanceRef?.current?.next()}
                  className={`${styles.carousel__button} ${nextDisabled ? styles.disabled : ''}`}
                  aria-controls={id}
                >
                  <Icon id='chevron-arrow' width={9} height={16} />
                  <span className={styles.carousel__buttonText}>
                    {nextLabel}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </>
      )}
      {isOverlay && (
        <>
          {showDots && (
            <ul
              className={`${styles.carousel__controlList} ${styles.carousel__overlay_dots}`}
            >
              {[
                ...Array(
                  instanceRef.current?.track.details.slides.length
                ).keys(),
              ].map((idx) => {
                const isCurrent = currentSlide === idx;
                return (
                  <li key={`${id}-overlay-dot-${idx}`} aria-current={isCurrent}>
                    <button
                      type='button'
                      className={`${styles.carousel__dot} ${
                        isCurrent ? styles.active : ''
                      }`}
                      onClick={() => instanceRef.current?.moveToIdx(idx)}
                      aria-label={slideLabel.replace(
                        slideCurrentToken,
                        `${idx + 1}`
                      )}
                      aria-controls={id}
                    />
                  </li>
                );
              })}
            </ul>
          )}
          <ul
            className={`${styles.carousel__controlList} ${styles.carousel__overlay_arrows}`}
          >
            <li>
              <button
                type='button'
                disabled={prevDisabled}
                onClick={() => !prevDisabled && instanceRef?.current?.prev()}
                className={`${styles.carousel__button} ${styles.prev} ${prevDisabled ? styles.disabled : ''}`}
                aria-controls={id}
              >
                <Icon id='chevron-arrow' width={9} height={16} />
                <span className={styles.carousel__buttonText}>
                  {previousLabel}
                </span>
              </button>
            </li>
            <li>
              <button
                type='button'
                disabled={nextDisabled}
                onClick={() => !nextDisabled && instanceRef?.current?.next()}
                className={`${styles.carousel__button} ${nextDisabled ? styles.disabled : ''}`}
                aria-controls={id}
              >
                <Icon id='chevron-arrow' width={9} height={16} />
                <span className={styles.carousel__buttonText}>{nextLabel}</span>
              </button>
            </li>
          </ul>
        </>
      )}

      {}
    </section>
  );
};

export default Carousel;
