import styles from './TinyMCERichText.module.scss';

import DOMPurify from 'isomorphic-dompurify';
import { useEffect, useState } from 'react';

const TinyMCERichText = ({
  text,
  className = '',
}: {
  text: string;
  className: string;
}) => {
  const [richText, setRichText] = useState<string>(DOMPurify.sanitize(text));

  useEffect(() => {
    // wrap tables in a div for horizontal scroll
    const cleanText = DOMPurify.sanitize(text);
    const cleanTextWithTableWrappers = cleanText
      .replaceAll(
        '<table',
        `<div data-table-wrapper role="region" tabindex="0"><table`
      )
      .replaceAll('</table>', '</table></div>');

    setRichText(cleanTextWithTableWrappers);
  }, [text]);

  return (
    <div
      className={`${styles.richText} ${className}`}
      dangerouslySetInnerHTML={{ __html: richText }}
    />
  );
};

export default TinyMCERichText;
