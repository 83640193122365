  import styles from './ComponentMediaGallery.module.scss';

import { MediaGallery, DataDigitalAsset } from '@/types';
import { MediaCard, useIsMobile } from '@/ui';
import { Carousel, CarouselSlide } from '@/ui/base';

const ComponentMediaGallery = ({
  backgroundColour,
  bodyTitle,
  bodyText,
  galleryAssets,
}: MediaGallery) => {
  const backgroundColourClass = backgroundColour?.toLowerCase().replace(' ', '-');

  const isMobile = useIsMobile();
  const isCarousel = galleryAssets.length > 2;

  return (
    <div
      className={`${styles.mediaGallery} ${styles.mediaGallery} bg__${backgroundColourClass}`}
    >
      <div className={styles.mediaGallery__container}>
        <div className={styles.mediaGallery__content}>
          <div className={styles.mediaGallery__contentInner}>
            {bodyTitle && (
              <h2 className={styles.mediaGallery__title}>{bodyTitle}</h2>
            )}
            {bodyText && (
              <div className={styles.mediaGallery__body}>{bodyText}</div>
            )}
          </div>
        </div>
        {!isCarousel ? (
          <div
            className={`${styles.mediaGallery__grid} ${
              galleryAssets.length <= 1 && styles.singleItem
            }`}
          >
            {galleryAssets?.map((item: DataDigitalAsset) => (
              <div
                key={item?.sys?.id}
                className={`${styles.mediaGallery__item}`}
              >
                <MediaCard {...item} />
              </div>
            ))}
          </div>
        ) : (
          <Carousel
            id={`media-gallery-carousel`}
            accessibleTitle='Media Gallery'
            controlsOverlay={true}
            className={styles.mediaGallery__carousel}
            slidesPerView={isMobile ? 1.05 : 2}
            spacing={isMobile ? 8 : 24}
            loop={false}
            showDots={false}
          >
            {galleryAssets?.map((item: DataDigitalAsset, idx) => (
              <CarouselSlide key={`slide__${item?.sys?.id}_${idx}`}>
                <div className={`${styles.mediaGallery__item}`}>
                  <MediaCard {...item} />
                </div>
              </CarouselSlide>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default ComponentMediaGallery;
